
import { Vue, Component, Prop } from "vue-property-decorator";
import anime from "animejs";
import Big from "bignumber.js";

@Component({})
export default class ClassicRoll extends Vue {
    @Prop()
    readonly higher: boolean | undefined;

    @Prop()
    readonly rollTarget: number | undefined;

    @Prop()
    readonly bet: Big | undefined;

    @Prop()
    readonly multiplier: Big | undefined;

    @Prop()
    readonly maxRoll: number | undefined;

    roll = "0";
    completed = false;
    won = false;

    waitForResult() {
        this.roll = "0";
        this.completed = false;

        this.$nextTick(function () {
            anime.remove(this);
            anime({
                targets: this,
                roll: this.maxRoll,
                easing: "linear",
                duration: 1000,
                round: 1,
                loop: true,
                direction: "alternate",
            });
        });
    }

    setResult(result: number | boolean) {
        const that: any = this;
        anime.remove(that);

        if (result === false) {
            that.completed = false;
            that.roll = 0;
            return;
        } else {
            that.roll = result;
            that.completed = true;
            that.won = that.higher
                ? result >= that.rollTarget
                : result < that.rollTarget;
        }

        // anime({
        //     targets: that,
        //     roll: result,
        //     easing: "linear",
        //     duration: 1000,
        //     round: 1,
        //     complete: () => {
        //         anime.remove(that);
        //         that.roll = result;
        //         that.completed = true;
        //         that.won = that.higher
        //             ? result >= that.rollTarget
        //             : result < that.rollTarget;
        //     }
        // });
    }
}
