
import { Vue, Component, Prop } from "vue-property-decorator";
import Big from "bignumber.js";
import { providers } from "ethers";
import { Contract, ethers } from "ethers";
import { getContract, getProvider } from "../contracts/helper";
import { BigNumber } from "ethers";
import feeSetup from "@/lib/fee-setup";

type Token = {
    id: string;
    gid: number;
    name: string;
    url: string;
    min: Big;
    dp: number;
    ddp: number;
    address: string;
};

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

@Component({})
export default class TokenDiceInvestment extends Vue {
    @Prop()
    readonly provider: providers.JsonRpcProvider | undefined;

    @Prop()
    readonly contract: Contract | undefined;

    @Prop()
    readonly token: Token | undefined;

    balance = new Big(0);
    totalBet = new Big(0);
    totalCost = new Big(0);
    totalGain = new Big(0);
    totalInvested = new Big(0);
    totalProfitReturned = new Big(0);

    showWarning = false;

    runningInvestment = new Big(0);
    myInvested = new Big(0);
    myInvestmentValue = new Big(0);

    loading = false;
    freezeAmountInput = "";

    toBig(v: any) {
        return new Big(v.toString());
    }

    mounted() {
        this.refresh();
    }

    async refresh() {
        this.loading = true;

        this.$nextTick(async function () {
            try {
                if (this.token && this.provider && this.contract) {
                    if (this.$store.state.selectedAccount) {
                        // load balances..
                        if (this.token.id == "TT") {
                            this.balance = toEther(
                                await this.provider.getBalance(
                                    this.$store.state.selectedAccount
                                )
                            );
                            this.runningInvestment = toEther(
                                await this.provider.getBalance(
                                    this.contract.address
                                )
                            );
                        } else {
                            const erc = getContract(
                                "ERC20",
                                this.provider,
                                this.token.address
                            );
                            this.balance = toEther(
                                await erc.balanceOf(
                                    this.$store.state.selectedAccount
                                ),
                                this.token.dp
                            );
                            this.runningInvestment = toEther(
                                await erc.balanceOf(this.contract.address),
                                this.token.dp
                            );
                        }

                        const frozen: {
                            who: string;
                            amount: BigNumber;
                            profit: BigNumber;
                            loss: BigNumber;
                        } = await this.contract.playerFreeze(
                            this.token.gid,
                            this.$store.state.selectedAccount
                        );

                        this.myInvested = toEther(
                            frozen.amount,
                            this.token.dp
                        ).div(0.95);

                        if (this.myInvested.gt(0)) {
                            this.myInvestmentValue = toEther(
                                await this.contract.freezeValue(
                                    this.token.gid,
                                    this.$store.state.selectedAccount
                                ),
                                this.token.dp
                            );
                        } else {
                            this.myInvestmentValue = new Big(0);
                        }
                    }

                    const tat = this as any;
                    // load stat..
                    for (const key of [
                        "totalBet",
                        "totalCost",
                        "totalGain",
                        "totalInvested",
                        "totalProfitReturned",
                    ]) {
                        tat[key] = toEther(
                            await tat.contract[key](this.token.gid)
                        );
                    }
                }
            } catch (error) {
                let message;
                if (error.data && error.data.message) {
                    message = error.data.message;
                } else {
                    message = error.message || error.toString();
                }

                window.$notify(message, "danger");
            } finally {
                this.loading = false;
            }
        });
    }

    investValue() {
        if (this.myInvested.gt(this.myInvestmentValue))
            return this.myInvestmentValue;
        else {
            const fee = this.myInvestmentValue
                .minus(this.myInvested)
                .times(0.2);
            return this.myInvestmentValue.minus(fee);
        }
    }

    startFreeze() {
        if (this.token) {
            const amount = prompt(
                `You have ${this.balance.dp(5)} ${this.token.id
                }\nHow much would you like to freeze?`,
                "0"
            );
            if (amount) {
                this.freezeAmountInput = amount;
                this.showWarning = true;
            }
        }
    }

    freeze() {
        if (this.token && this.provider && this.contract) {
            this.loading = true;

            this.$nextTick(async function () {
                try {
                    if (!(this.token && this.provider && this.contract)) return;

                    const amount = this.freezeAmountInput;
                    if (amount) {
                        const b = new Big(amount);

                        if (!(b.isFinite() && !b.isNaN() && b.gt(0))) return;

                        const p = getProvider(
                            this.$store.state.selectedProvider,
                            false
                        );
                        const signer = p.getSigner();
                        const account = await signer.getAddress();
                        const contract = this.contract.connect(signer);

                        if (this.token.id == "TT") {
                            const t = await contract.freeze(
                                this.token.gid,
                                b.times(1e18).dp(0, Big.ROUND_DOWN).toString(),
                                {
                                    ...feeSetup(),
                                    value: ethers.BigNumber.from(
                                        b.times(1e18).toString()
                                    ),
                                }
                            );

                            await t.wait(1);

                            this.$emit("doneInvest");
                        } else {
                            const erc = getContract(
                                "ERC20",
                                signer,
                                this.token.address
                            );
                            const allowance = toEther(
                                await erc.allowance(account, contract.address),
                                this.token.dp
                            );

                            // console.log(contract.address, allowance.toString());
                            let approving = false;

                            if (allowance.lt(b)) {
                                alert(
                                    "please allow the contract to spend your " +
                                    this.token.id
                                );

                                approving = true;

                                const t = await erc.approve(
                                    contract.address,
                                    b
                                        .times(`1e${this.token.dp}`)
                                        .times(1e22)
                                        .dp(0)
                                        .toString()
                                );
                                await t.wait(1);
                                console.log("approved", contract.address);
                            }

                            if (approving) {
                                alert("please confirm the freeze transaction");
                            }

                            const t = await contract.freeze(
                                this.token.gid,
                                b
                                    .times(`1e${this.token.dp}`)
                                    .dp(0, Big.ROUND_DOWN)
                                    .toString(),
                                {
                                    ...feeSetup()
                                }
                            );

                            await t.wait(1);

                            this.$emit("doneInvest");
                        }

                        this.showWarning = false;

                        return this.refresh();
                    }
                } catch (error) {
                    let message;
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    } else {
                        message = error.message || error.toString();
                    }

                    window.$notify(message, "danger");
                } finally {
                    this.loading = false;
                }
            });
        }
    }

    unfreeze() {
        if (this.token && this.provider && this.contract) {
            this.loading = true;

            this.$nextTick(async function () {
                try {
                    if (!(this.token && this.provider && this.contract)) return;

                    const p = getProvider(
                        this.$store.state.selectedProvider,
                        false
                    );
                    const signer = p.getSigner();
                    const account = await signer.getAddress();
                    const contract = this.contract.connect(signer);

                    {
                        const erc = getContract(
                            "ERC20",
                            signer,
                            this.token.address
                        );

                        console.log(this.token.gid);

                        console.log(
                            await contract.gameIdToToken(this.token.gid),
                            this.token.address
                        );

                        const frozen = await contract.playerFreeze(
                            this.token.gid,
                            account
                        );

                        console.log(
                            account,
                            frozen.who,
                            toEther(frozen.amount, this.token.dp).toFormat()
                        );

                        const tokenBalanceAtContract = toEther(
                            await erc.balanceOf(contract.address),
                            this.token.dp
                        );
                        const amount = toEther(frozen.amount, this.token.dp);
                        const available = toEther(
                            await contract.freezeValue(this.token.gid, account),
                            this.token.dp
                        );
                        const forDev = available.minus(amount).times(0.2);

                        console.log(
                            amount.toString(),
                            available.toString(),
                            forDev.toString(),
                            available.minus(forDev).toString(),
                            tokenBalanceAtContract.toFormat()
                        );
                    }

                    const t = await contract.unfreeze(this.token.gid);

                    await t.wait(1);

                    this.$emit("doneInvest");

                    return this.refresh();
                } catch (error) {
                    let message;
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    } else {
                        message = error.message || error.toString();
                    }

                    window.$notify(message, "danger");
                } finally {
                    this.loading = false;
                }
            });
        }
    }
}
