
import { Component, Vue, Watch } from "vue-property-decorator";

import * as ethers from "ethers";
import jsCookie from "js-cookie";

import detectReferralMixin from "../detect-referral-mixin";
import ContractMonitoring from "../contracts/contract-monitoring";
import ClassicRoll from "../components/dice/ClassicRoll.vue";

import Big from "bignumber.js";
Big.config({ EXPONENTIAL_AT: 256 });

import { BigNumber, providers } from "ethers";
import { getContract, getProvider } from "../contracts/helper";
import { Contract } from "ethers";
import TokenDiceInvestment from "./_TokenDiceInvestment.vue";
import tokenDiceTokens from "./tokendice-tokens";
import feeSetup from "@/lib/fee-setup";

const edge = 0.9;
const afterEdge = 100 - edge;

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

type BetData = {
    id: string;
    token: string;
    gid: Big;
    player: string;
    higher: boolean;
    mark: Big;
    roll: Big;
    amount: Big;
    payout: Big;
};

type Token = {
    id: string;
    gid: number;
    name: string;
    url: string;
    min: Big;
    dp: number;
    ddp: number;
    address: string;
};

@Component({
    mixins: [detectReferralMixin],
    components: { ClassicRoll, TokenDiceInvestment },
    computed: {
        profit() {
            const that: any = this;
            return new Big(that.betValue)
                .times(that.multiplier)
                .decimalPlaces(4, Big.ROUND_DOWN)
                .toNumber();
        },
    },
    watch: {
        mark() {
            const that: any = this;
            if (that.focus == "mark") {
                that.multiplier = that.calcMultiplier(that.mark);
            }
        },

        multiplier() {
            const that: any = this;
            if (that.focus == "multiplier") {
                // multiplier = afterEdge/100 * (100/mark)
                // multiplier/(afterEdge/100) = 100/mark
                // mark = 100/(multiplier/(afterEdge/100))

                const mark = new Big(1e4).div(
                    new Big(that.multiplier).div(afterEdge).times(100)
                );

                that.mark = mark.decimalPlaces(0, Big.ROUND_DOWN).toNumber();
            }
        },
    },
})
export default class TokenDice extends Vue {
    showBets = "all";
    bets: BetData[] = [];
    myBets: BetData[] = [];
    betValue = 1;
    higher = true;
    mark = 5000;
    multiplier = this.calcMultiplier(this.mark);
    focus = "";
    minMark = 200;
    maxMark = 9800;
    loading = false;
    robot = {
        enable: false,
        rolls: 0,
        onWin: "reset",
        onWinIncrease: 100,
        onLoss: "reset",
        onLossIncrease: 100,
        stopOnProfit: 0,
        stopOnLoss: 0,
        runningProfit: new Big(0),
        running: false,
        betValue: 0,
        count: 0,
    };

    provider: providers.JsonRpcProvider | null = null;
    contract: Contract | null = null;
    ercContract: Contract | null = null;

    unlocked: { [gid: string]: boolean } = {};

    tokens: Token[] = tokenDiceTokens;

    selectedToken: Token | null = this.tokens[1];
    tokenToWin = new Big(0);
    myTokenBalance = new Big(0);

    @Watch("selectedToken")
    onSelectedTokenChange() {
        this.refreshAvailableToWin();
    }

    beforeDestroy() {
        this.cleanUp();
    }

    cleanUp() {
        this.provider?.removeAllListeners();
        this.contract?.removeAllListeners("Bet");
        this.ercContract?.removeAllListeners("Transfer");
    }

    beforeMount() {
        const storedTokenId = localStorage.getItem("TokenDiceTokenID");

        if (storedTokenId) {
            const token = this.tokens.find((v) => v.id == storedTokenId);
            if (token) {
                this.selectedToken = token;

                this.$store.commit("balanceWatchToken", {
                    symbol: token.id,
                    dp: token.dp,
                    ddp: token.ddp,
                    address: token.address,
                });
            }
        }
    }

    mounted() {
        const provider = getProvider("", true);
        const contract = getContract("TokenDice", provider);

        this.provider = provider;
        this.contract = contract;

        this.refreshAvailableToWin();

        contract.on(
            "Bet",
            (
                gid: BigNumber,
                player: string,
                higher: boolean,
                mark: BigNumber,
                roll: BigNumber,
                amount: BigNumber,
                payout: BigNumber,
                e: any
            ) => {
                const token = this.tokens.find((v) => gid.eq(v.gid.toString()));

                if (token) {
                    const item = {
                        id: e.transactionHash,
                        gid: toEther(gid, 0),
                        token: token.id,
                        player,
                        higher,
                        mark: toEther(mark, 0),
                        roll: toEther(roll, 0),
                        amount: toEther(amount, token.dp),
                        payout: toEther(payout, token.dp),
                    };

                    this.bets.unshift(item);

                    if (this.$store.state.selectedAccount == player) {
                        this.myBets.unshift(item);
                        this.myBets.splice(100);
                    }

                    this.bets.splice(40);
                }
            }
        );
    }

    setMinBet() {
        this.loading = true;
        this.$nextTick(async function () {
            try {
                const token = this.selectedToken;
                if (!token) return;

                const p = getProvider(this.$store.state.selectedProvider);
                const signer = p.getSigner();
                let account: string;

                try {
                    account = await signer.getAddress();
                } catch (error) {
                    return window.$notify("NO ACCOUNT", "danger");
                }

                const contract = getContract("TokenDice", signer);
                const t = await contract.setMinBet(
                    token.gid,
                    new Big("0.001").times(`1e${token.dp}`).toString()
                );
                console.log("done", t.hash);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        });
    }

    async refreshAvailableToWin() {
        if (this.selectedToken && this.provider && this.contract) {
            const provider = this.provider;
            const contract = this.contract;
            const token = this.selectedToken;

            if (this.selectedToken.id == "TT") {
                provider.removeAllListeners(contract.address);

                this.tokenToWin = toEther(
                    await provider.getBalance(contract.address)
                );

                provider.on(contract.address, (balance: BigNumber) => {
                    this.tokenToWin = toEther(balance);
                });
            } else {
                this.ercContract?.removeAllListeners("Transfer");

                this.ercContract = getContract(
                    "ERC20",
                    provider,
                    token.address
                );

                const getTokenToWin = async () => {
                    if (this.ercContract) {
                        this.tokenToWin = toEther(
                            await this.ercContract.balanceOf(contract.address),
                            token.dp
                        );
                    }
                };

                getTokenToWin();
            }
        }
    }

    setHigher(higher: boolean) {
        const that = this;
        if (that.robot.running) return;
        that.higher = higher;
        that.focus = "higher";
        that.multiplier = that.calcMultiplier(that.mark);
    }

    flashMark(mark: number) {
        const that = this;
        that.focus = "mark";
        that.mark = mark;
    }

    startInvestInBankRoll() {
        const win = window as any;
        (this.$refs.tokenDiceInvestment as any).refresh();
        win.$("#investment-panel").modal("show");
    }

    doneInvest() {
        const win = window as any;
        win.$("#investment-panel").modal("show");
    }

    calcMultiplier(mark: number): number {
        const that = this;
        const num = that.higher ? 10000 - mark : mark;
        return new Big(10000)
            .div(num)
            .times(afterEdge)
            .div(100)
            .decimalPlaces(3, Big.ROUND_DOWN)
            .toNumber();
    }

    selectToken(token: Token) {
        // so the investment content get mounted again
        this.selectedToken = null;
        this.$nextTick(function () {
            this.selectedToken = token;
            localStorage.setItem("TokenDiceTokenID", token.id.toString());

            // for showing the balance..
            this.$store.commit("balanceWatchToken", {
                symbol: token.id,
                dp: token.dp,
                ddp: token.ddp,
                address: token.address,
            });
        });
    }

    bet(showNotif: boolean, byRobot = false): Promise<number | boolean> {
        const that = this;
        that.loading = true;

        return new Promise((resolve, reject) => {
            that.$nextTick(async function () {
                try {
                    const rollBox = this.$refs.roll as any;

                    const contract = that.contract;
                    const provider = that.provider;
                    const token = that.selectedToken;

                    if (!(contract && provider && token))
                        return window.$notify(
                            "Could not connect to blockchain, please refresh",
                            "danger"
                        );

                    let maxBet: Big;
                    let coin;

                    const p = getProvider(this.$store.state.selectedProvider);
                    const signer = p.getSigner();
                    let account: string;

                    try {
                        account = await signer.getAddress();
                    } catch (error) {
                        resolve(false);
                        return window.$notify("NO ACCOUNT", "danger");
                    }

                    const tokenContract = token.address
                        ? getContract("ERC20", signer, token.address)
                        : null;

                    if (token.id == "TT") {
                        maxBet = toEther(
                            await provider.getBalance(contract.address)
                        ).div(500);
                    } else {
                        if (!tokenContract) {
                            resolve(false);
                            return window.$notify(
                                "NO TOKEN CONTRACT",
                                "danger"
                            );
                        }

                        maxBet = toEther(
                            await tokenContract.balanceOf(contract.address),
                            token.dp
                        ).div(500);
                    }

                    if (new Big(that.betValue).gt(maxBet)) {
                        resolve(false);
                        return window.$notify(
                            `Maximum bet is ${maxBet}TT`,
                            "info"
                        );
                    }

                    if (token.min.gt(that.betValue)) {
                        resolve(false);
                        return window.$notify(
                            `Minimum bet is ${token.min}TT`,
                            "info"
                        );
                    }

                    if (new Big(that.mark).lt(that.minMark)) {
                        resolve(false);
                        return window.$notify(
                            `Minimum mark is ${that.minMark}`,
                            "info"
                        );
                    }

                    if (new Big(that.mark).gt(that.maxMark)) {
                        resolve(false);
                        return window.$notify(
                            `Maximum mark is is ${that.maxMark}`,
                            "info"
                        );
                    }

                    try {
                        rollBox.waitForResult();

                        let tx, receipt;
                        let retry = 1;
                        const mark = new Big(this.mark);

                        const game = getContract("TokenDice", signer);

                        const amount = new Big(this.betValue)
                            .times(`1e${token.dp}`)
                            .dp(0, Big.ROUND_DOWN);

                        let approving = false;

                        if (tokenContract) {
                            const allowance: BigNumber = await tokenContract.allowance(
                                account,
                                contract.address
                            );

                            if (allowance.lt(amount.toString())) {
                                alert(
                                    "Please allow the contract to spend your " +
                                    token.id
                                );

                                approving = true;

                                const t = await tokenContract.approve(
                                    contract.address,
                                    amount.times(1e22).toString(),
                                    { ...feeSetup() }
                                );

                                await t.wait(1);
                            }
                        }

                        while (retry > 0) {
                            try {
                                let txOptions: any = {};

                                if (token.id == "TT") {
                                    txOptions.value = ethers.BigNumber.from(
                                        amount.toString(),
                                    );
                                }

                                txOptions = { ...txOptions, ...feeSetup() };

                                // const minBet: BigNumber = await game.minBet(
                                //     token.gid
                                // );
                                // console.log(
                                //     "min bet",
                                //     minBet.toString(),
                                //     amount.toString()
                                // );

                                if (approving) {
                                    alert("please confirm the bet transaction");
                                }

                                const myArgs = [
                                    this.higher,
                                    mark.toString(),
                                    amount.toString(),
                                    token.gid,
                                    txOptions
                                ];

                                const gasLimit: BigNumber = await game.estimateGas.bet.apply(this, myArgs);

                                txOptions.gasLimit = gasLimit.mul(13).div(10);

                                tx = await game.bet.apply(this, myArgs);

                                receipt = await provider.waitForTransaction(
                                    tx.hash
                                );

                                break;
                            } catch (error) {
                                if (
                                    error.toString().indexOf("nonce too low") >=
                                    0
                                ) {
                                    console.log(
                                        "nonce too low.. retrying..",
                                        retry
                                    );

                                    let tryNonce = 1;
                                    while (tryNonce <= 3) {
                                        const nonce = await signer.getTransactionCount();
                                        console.log(
                                            `current nonce: ${nonce}, block: ${provider.blockNumber}`
                                        );

                                        await sleep(5000);
                                        const newNonce = await signer.getTransactionCount();

                                        if (newNonce > nonce) break;
                                        ++tryNonce;

                                        if (tryNonce >= 4) {
                                            throw `3 times trying to fetch new nonce failed.. ${nonce}, block number: ${provider.blockNumber}`;
                                        }
                                    }

                                    ++retry;
                                    if (retry >= 4) {
                                        throw error;
                                    }
                                } else {
                                    throw error;
                                }
                            }
                        }

                        if (receipt && receipt.logs) {
                            const events = receipt.logs
                                .map((v: any) => {
                                    try {
                                        return contract.interface.parseLog(v);
                                    } catch (error) {
                                        return null;
                                    }
                                })
                                .filter(
                                    (v: any) => Boolean(v) && v.name == "Bet"
                                );

                            if (events.length == 0 || !events[0]) {
                                throw "transaction failed, please check your balance";
                            }

                            const roll = events[0].args["roll"].toString();
                            rollBox.setResult(roll);

                            const win = toEther(
                                events[0].args["payout"],
                                token.dp
                            );

                            if (win.eq(0)) {
                                if (showNotif)
                                    window.$notify(
                                        `Lost ${that.betValue} ${token.id}`,
                                        "danger"
                                    );
                                return resolve(-that.betValue);
                            } else {
                                if (showNotif)
                                    window.$notify(
                                        `You WON ${win
                                            .dp(6, Big.ROUND_DOWN)
                                            .toFormat()} ${token.id}`,
                                        "success"
                                    );
                                return resolve(win.toNumber());
                            }
                        } else {
                            throw "transaction is not confirmed, please check your balance and try again.";
                        }
                    } catch (error) {
                        rollBox.setResult(false);

                        if (byRobot) reject(error);

                        let message;
                        if (error.data && error.data.message) {
                            message = error.data.message;
                        } else {
                            message = error.message || error.toString();
                        }

                        resolve(false);

                        window.$notify(message, "danger");
                    } finally {
                        that.loading = false;
                    }
                } finally {
                    this.loading = false;
                }
            });
        });
    }

    async startRobot() {
        const that = this;
        that.robot.running = true;
        that.robot.runningProfit = new Big(0);
        that.bets = [];

        let betValue = that.betValue;
        that.robot.betValue = betValue;

        if (that.robot.stopOnLoss < 0) {
            alert("stop on loss should be greater than 0");
            return that.stopRobot();
        }
        if (that.robot.stopOnProfit < 0) {
            alert("stop on profit should be greater than 0");
            return that.stopRobot();
        }

        that.robot.count = 0;

        while (that.robot.running) {
            ++that.robot.count;

            let won;
            try {
                won = await that.bet(false, true);
            } catch (error) {
                let m;
                if (error.data && error.data.message) m = error.data.message;
                else m = error.message || error.toString();

                alert(m);

                return that.stopRobot();
            }

            if (typeof won == "boolean") return;

            const profit = won < 0 ? won : won - betValue;

            that.robot.runningProfit = that.robot.runningProfit.plus(profit);

            if (that.robot.runningProfit.gt(0)) {
                if (
                    that.robot.stopOnProfit > 0 &&
                    that.robot.runningProfit.gte(that.robot.stopOnProfit)
                ) {
                    that.stopRobot();
                    break;
                }
            } else {
                if (
                    that.robot.stopOnLoss > 0 &&
                    that.robot.runningProfit.abs().gte(that.robot.stopOnLoss)
                ) {
                    that.stopRobot();
                    break;
                }
            }

            if (won > 0) {
                if (that.robot.onWin == "reset") {
                    betValue = that.robot.betValue;
                } else {
                    const multiplier = new Big(that.robot.onWinIncrease)
                        .div(100)
                        .plus(1);

                    betValue = multiplier
                        .times(betValue)
                        .decimalPlaces(4)
                        .toNumber();
                }
            } else {
                if (that.robot.onLoss == "reset") {
                    betValue = that.robot.betValue;
                } else {
                    const multiplier = new Big(that.robot.onLossIncrease)
                        .div(100)
                        .plus(1);

                    betValue = multiplier
                        .times(betValue)
                        .decimalPlaces(4)
                        .toNumber();
                }
            }

            that.betValue = betValue;
            await sleep(1000);
        }
    }

    stopRobot() {
        const that = this;
        that.robot.running = false;
        that.betValue = that.robot.betValue;
    }

    domain(v: string) {
        return v.substring(8);
    }

    async betValueGetMin() {
        this.loading = true;
        this.$nextTick(async function () {
            try {
                if (this.contract && this.selectedToken) {
                    const min = await this.contract.minBet(
                        this.selectedToken.gid
                    );
                    this.betValue = toEther(
                        min,
                        this.selectedToken.dp
                    ).toNumber();
                    return;
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                this.loading = false;
            }

            this.betValue = 0.001;
        });
    }

    betValueGetMax() {
        this.loading = true;
        this.$nextTick(async function () {
            try {
                if (this.contract && this.selectedToken && this.provider) {
                    if (this.selectedToken.id == "TT") {
                        const balance = this.provider.getBalance(
                            this.contract.address
                        );
                        this.tokenToWin = toEther(balance);
                        this.betValue = this.tokenToWin
                            .div(500)
                            .times(0.95)
                            .dp(8)
                            .toNumber();
                        return;
                    } else {
                        if (this.ercContract) {
                            const balance = await this.ercContract.balanceOf(
                                this.contract.address
                            );
                            this.tokenToWin = toEther(
                                balance,
                                this.selectedToken.dp
                            );
                            this.betValue = this.tokenToWin
                                .div(500)
                                .times(0.95)
                                .dp(8)
                                .toNumber();
                            return;
                        }
                    }
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                this.loading = false;
            }

            this.betValue = 0.001;
        });
    }
}
